import React from "react";
import "./Contact.css";

function Contact() {
  return (
    <div id="contact">
      <div class="container">
        <div class="content">
          <p>Reach out to me on the interwebs</p>
          <div class="footer-items">
            <div class="footer-item">
              <a
                href="https://www.linkedin.com/in/filipskrzesinski"
                target="_blank"
                rel="noopener noreferrer"
                class="link"
              >
                LinkedIn
              </a>
            </div>
            <div class="footer-item">
              <a
                href="https://www.instagram.com/spiritual.puberty/"
                target="_blank"
                rel="noopener noreferrer"
                class="link"
              >
                Instagram
              </a>
            </div>
            <div class="footer-item">
              <a
                href="https://github.com/filipskrzesinski"
                target="_blank"
                rel="noopener noreferrer"
                class="link"
              >
                Github
              </a>
            </div>
            <div class="footer-item">
              <a
                href="https://open.spotify.com/user/filipskrzesinski/playlist/2PXVwqkVP5N62XplIHUUii?si=B_Hbr10QT3SAHuHVytNX-Q"
                target="_blank"
                rel="noopener noreferrer"
                class="link"
              >
                Now Playing
              </a>
            </div>
          </div>
          <div class="footer-copy" id="year">
            <span>© 2025</span>
            <span>chirp chirp #47</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
