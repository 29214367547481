import React from "react";
import ThemeToggle from "./ThemeToggle";
import "./About.css";

function About() {
  return (
    <div id="about">
      <div class="container">
        <div class="content">
          <div class="about">
            <div class="about-header">
              <h2 class="about-name">Filip Skrzesinski</h2>
              <div class="about-actions">
                <ThemeToggle />
                <a
                  class="about-albers"
                  href="https://g-e-s-t-a-l-t.org/media/pdf/Interaction-of-Color.pdf"
                  rel="noopener"
                >
                  <div class="albers-outer" />
                  <div class="albers-middle" />
                  <div class="albers-inner" />
                </a>
              </div>
            </div>
            <h1 class="about-heading">
              {
                "Building Subframe —\nthe AI design-to-code tool from the future."
              }
            </h1>
            <div className="about-links">
              <a href="https://subframe.com" target="_blank" rel="noopener">
                @subframe (co-founder)
              </a>
              <a href="https://erria.xyz" target="_blank" rel="noopener">
                @erria
              </a>
              <a href="https://affinity.co" target="_blank" rel="noopener">
                @affinity
              </a>
              <a href="https://smith.ai" target="_blank" rel="noopener">
                @smith.ai
              </a>
              <a href="https://esri.com" target="_blank" rel="noopener">
                @esri
              </a>
            </div>
            <p class="about-byline">
              <ul>
                <li> ambiguity &nbsp;→&nbsp; clarity</li>
                <li> chaos &nbsp;→&nbsp; harmony</li>
                <li> frustration &nbsp;→&nbsp; delight</li>
                <li> uncertainty &nbsp;→&nbsp; confidence</li>
                <li> complexity &nbsp;→&nbsp; simplicity</li>
                <li> ideas &nbsp;→&nbsp; reality</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
